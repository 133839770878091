.toric-table-content {
  width: 100%;
}

.toric-table {
  width: 100%;
  border: 1px solid;
  text-align: center;
  border-collapse: collapse;
}

.toric-table-thead {
}
.toric-table-th {
  border: 1px solid black;
  background-color: #ececec;
}
.toric-table-tr {
}
.toric-table-td-leader {
  border: 1px solid black;
  background-color: #1e2537;
  color: #ececec;
}
.toric-table-td {
  border: 1px solid black;
  min-width: 20px;
}

.toric-table-td:hover {
  opacity: 0.6;
}
