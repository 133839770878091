.summary-table-content {
    width: 100%;
}

.summary-table {
    width: 100%;
    border: 1px solid;
    text-align: center;
    border-collapse: collapse;
}

.summary-table-th {
    border: 1px solid black;
    background-color: #ececec;
}

.summary-table-td-leader {
    border: 1px solid black;
    background-color: #ececec;
    font-weight: bold;
}

.summary-table-td {
    border: 1px solid black;
    min-width: 20px;
}

/* .summary-table-td:hover {
    opacity: 0.6;
} */

.summary-info {
    display: inline;
    font-weight: bold;
    font-size: 16px;
    color: blue;
}
